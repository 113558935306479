.spinner {
  position: relative;
  width: 25rem;
  height: 16rem;
  pointer-events: none;
}

.spinner.mini {
  width: 1.5rem;
  height: 1.5rem;
  opacity: 0.5;
  animation: rotation 4s infinite linear;
}

.spinner svg {
  position: absolute;
  fill: #ffffff;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}
