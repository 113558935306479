/* Loading */

#loading {
  position: fixed;
  top: 0;
  left: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  max-width: unset;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  align-content: center;
  text-align: center;
  z-index: 2;
  background-color: #be4600;
  pointer-events: none;
}

.wrapper.dark-mode #loading {
  background-color: #be4600;
}

#loading .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0f0f0f;
  padding: 60px;
  display: grid;
  overflow: hidden;
}

#loading .bg .container {
  width: 100%;
  max-width: 839px;
  align-self: center;
  justify-self: center;
}

#loading .bg .container svg {
  fill: #131214;
  opacity: 0.1;
  transform: scale(2);
}

#loading .text {
  position: absolute;
  bottom: 7rem;
  left: 0;
  width: 100%;
  font-family: var(--fontText);
  display: grid;
  grid-gap: 2rem;
  justify-content: center;
}

#loading .text .version {
  font-size: 14px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.8);
}

#loading .text .status {
  font-size: 12px;
  letter-spacing: 2px;
  color: rgba(255, 255, 255, 0.4);
  text-transform: uppercase;
  display: grid;
  grid-template-columns: min-content max-content;
  grid-gap: 1rem;
  align-items: center;
  width: max-content;
}

#loading .text .status.error {
  grid-template-columns: 1fr;
  width: auto;
}

#loading .logo {
  position: relative;
  flex-basis: 100%;
  display: flex;
  justify-content: center;
}

#loading .logo .device {
  position: relative;
  width: 400px;
  pointer-events: none;
}

#loading .logo .device svg {
  fill: #502d8f;
  opacity: 0.8;
  width: 100%;
  height: auto;
}

@media screen and (orientation: portrait) {
}

@media screen and (max-width: 600px) {
  #loading .logo .device {
    width: 48px;
    font-size: 48px;
    line-height: 42px;
  }

  #loading .logo .device::before,
  #loading .logo .device::after {
    width: 136px;
    height: 136px;
    top: -45px;
    left: -45px;
  }
}
